<template>
    <div v-if="modelValue">
        <template v-if="type == 1 && !isPreview">
            <h3 class="sub_heading2 mt-5 mb-2">Send Test Email</h3>
            <div class="notif_preview_wpr">
                <Form @submit="handleTestEmail" v-slot="{ errors }">
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Email Address</label>
                            <div class="field_wpr has_prefix" :class="{ 'has-error': errors.email }">
                                <Field autocomplete="off" name="email" type="email" v-model="form.email_address" placeholder="john.doe@onboardme.io" rules="required|email" />
                                <span class="prefix"><i class="fas fa-envelope"></i></span>
                            </div>
                            <ErrorMessage name="email" class="text-danger" />
                        </div>
                    </div>
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Email Style</label>
                            <div class="field_wpr">
                                <multiselect
                                    v-model="form.email_style"
                                    :options="emailStyles"
                                    value-prop="value"
                                    label="label"
                                    :searchable="true"
                                    placeholder="Email Style"
                                ></multiselect>
                            </div>
                        </div>
                    </div>
                    <div class="action_wpr mt-4">
                        <button class="btn cancel_btn" :disabled="testEmailSMSLoader" type="button" @click="closeSendEmailSMS">Cancel</button>
                        <button class="btn save_btn" :disabled="testEmailSMSLoader">
                            <i v-if="testEmailSMSLoader" class="fa fa-spinner fa-spin"></i>
                            {{ testEmailSMSLoader ? 'Sending' : 'Send Preview' }}
                        </button>
                    </div>
                </Form>
            </div>
        </template>
        <template v-else-if="type == 2 && !isPreview">
            <h3 class="sub_heading2 mt-4 mb-2">Send Test SMS</h3>
            <div class="notif_preview_wpr">
                <Form @submit="handleTestSMS">
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Phone Number</label>
                            <div class="field_wpr" :class="{ 'has-error': hasNumberError }">
                                <input type="text" v-model="form.phone_number" />
                            </div>
                            <span class="text-danger" v-show="hasNumberError">{{ numberErrorMessage }}</span>
                        </div>
                    </div>
                    <div class="action_wpr mt-3">
                        <button class="btn cancel_btn" :disabled="testEmailSMSLoader" type="button" @click="closeSendEmailSMS">Cancel</button>
                        <button class="btn save_btn" :disabled="testEmailSMSLoader">
                            <i v-if="testEmailSMSLoader" class="fa fa-spinner fa-spin"></i>
                            {{ testEmailSMSLoader ? 'Sending' : 'Send Preview' }}
                        </button>
                    </div>
                </Form>
            </div>
        </template>
    </div>
    <div class="preview_area" v-if="isPreview" :class="{ active : isPreview }" @click.self="closePreview()">
        <div class="dashboard">
            <button type="button" class="btn cancel_btn" @click="closePreview()"><i class="fas fa-times"></i></button>
            <div class="msg_preview">
                <div class="loader_area3" v-if="testEmailSMSLoader"><quote-loader/></div>
                <div class="preview-content m-0" v-if="!testEmailSMSLoader">
                    <div v-if="type == 1">
                        <h2>{{ testEmailContent.subject }}</h2>
                        <div class="sender_info">
                            <img src="@/assets/images/robert.png" alt="">
                            <div class="info">
                                <h5>Thrive Coach</h5>
                                <h6><span>To:</span>{{ user.full_name }}</h6>
                            </div>
                        </div>
                        <div class="email-logo" v-if="testEmailContent.display_logo">
                            <img :src="testEmailContent.logo" />
                        </div>
                        <div class="redactor-styles redactor-in redactor-in-0 p-0" v-html="testEmailContent.content"></div>
                    </div>
                    <pre v-else v-html="testSMSContent.content"></pre>
                </div>
                <div v-if="type == 1 && !testEmailSMSLoader" class="msgFooter text-center mt-4 mb-2">
                    <p>
                        <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                            <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                            <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                        </template>
                        <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
                    </p>
                    <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                    <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from '@vueform/multiselect'

    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions, mapMutations } from 'vuex'

    export default {
        data () {
            return {
                emailStyles: [{
                    value: 1,
                    label: 'Simple'
                }, {
                    value: 2,
                    label: 'Corporate'
                }],
                form: {
                    content: '',
                    subject: '',
                    module: '',
                    email_address: '',
                },
                whiteLabel: {},
                hasNumberError: false,
                numberErrorMessage: '',
            };
        },

        props: {
            type: Number,
            modelValue: Boolean,
            module: String,
            postParams: Object,
            isPreview: Boolean,
        },

        emits: ['update:modelValue', 'update:isPreview', 'closePreview'],

        components: {
            Multiselect,
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            postParams: {
                handler (content) {
                    const vm = this;

                    vm.form = JSON.parse(JSON.stringify(content));
                    vm.form.module = vm.module;

                    if (vm.type == 1) {
                        vm.form.email_address = vm.user.test_preview_email ? vm.user.test_preview_email : vm.user.email;
                        vm.form.content = vm.form.email;
                    } else if (vm.type == 2) {
                        vm.form.phone_number = vm.user.sms_notification_number;
                    }

                    vm.whiteLabel = vm.user.white_label ? JSON.parse(JSON.stringify(vm.user.white_label)) : {};
                },
                deep: true
            },

            modelValue (val) {
                const vm = this;

                if (val) {
                    vm.form = JSON.parse(JSON.stringify(vm.postParams));
                    vm.form.module = vm.module;

                    if (vm.type == 1) {
                        vm.form.email_address = vm.user.test_preview_email ? vm.user.test_preview_email : vm.user.email;
                        vm.form.content = vm.form.email;
                    } else if (vm.type == 2) {
                        vm.form.phone_number = vm.user.sms_notification_number;
                    }

                    vm.whiteLabel = vm.user.white_label ? JSON.parse(JSON.stringify(vm.user.white_label)) : {};
                }
            },

            isPreview (val) {
                if (val) {
                    const vm = this;

                    vm.setTestEmailSMSLoaderStatus(true);

                    setTimeout(function () {
                        if (vm.type == 1) {
                            vm.previewTestEmail(vm.form);
                        } else {
                            vm.previewTestSMS(vm.form);
                        }
                    }, 100);
                }
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            membership: state => state.authModule.membership,
            testEmailSMSLoader: state => state.commonModule.testEmailSMSLoader,
            testEmailContent: state => state.commonModule.testEmailContent,
            testSMSContent: state => state.commonModule.testSMSContent,
        }),

        methods: {
            ...mapActions({
                previewTestEmail: 'commonModule/previewTestEmail',
                sendTestEmail: 'commonModule/sendTestEmail',
                previewTestSMS: 'commonModule/previewTestSMS',
                sendTestSMS: 'commonModule/sendTestSMS',
            }),

            ...mapMutations({
                setTestEmailSMSLoaderStatus: 'commonModule/SET_TEST_EMAIL_SMS_LOADER_STATUS',
            }),

            closeSendEmailSMS () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            closePreview () {
                const vm = this;

                vm.$emit('closePreview', false);
            },

            validate() {
                const vm = this;
                let isValid = true;

                vm.hasNumberError     = false;
                vm.numberErrorMessage = '';

                if (!vm.form.phone_number) {
                    isValid = false;

                    vm.hasNumberError     = true;
                    vm.numberErrorMessage = 'The phone number field is required.'
                }

                return isValid;
            },

            handleTestEmail (params) {
                const vm = this;

                vm.sendTestEmail(vm.form);
            },

            handleTestSMS () {
                const vm = this;

                if (vm.validate()) {
                    vm.sendTestSMS(vm.form);
                }
            },
        },
    }
</script>

<style scoped>
    .dashboard{
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: scroll;
        align-items: center;
        text-align: left;
        background: rgb(245, 245, 245);
        padding: 30px;
    }
    .msg_preview{
        max-width: 800px;
        width: 100%;
        margin: 30px 0 20px 0;
        position: relative;
        /* padding: 30px; */
    }
    .modal_header{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    /* .cancel_btn {
        border: 0;
        background: rgba(50,55,59,.6);
        font-size: 12px;
        color: #fff;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        min-width: 20px;
    } */
    .modal.preview_modal > .modal_container{
        max-width: 800px;
        width: 100%;
        border-radius: 3px;
    }
    .modal_body {
        min-height: 200px;
        max-height: 600px;
        padding: 30px;
    }

    .preview-content {
        padding: 30px;
        flex: 0 1 640px;
        margin: 50px auto;
        border: 1px solid #e9e9e9;
        background-color: rgb(255, 255, 255);
        overflow: initial;
        border-radius: 10px;
        position: relative;
    }
    .preview-content:after{
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }

    pre {
        font-size: 16px;
        line-height: 32px;
        font-weight: 400;
        color: #0e101a;
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        color: #2c3e50;
        white-space: pre-line;
    }
    :deep(.preview-content p){
        font-size: 16px;
        line-height: 32px;
        color: #5a5a5a;
        font-weight: 400;
        margin-bottom: 7px;
    }
    /* :deep(.preview-content p a){
        margin: 0 !important;
    } */
    .preview-content h2{
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 25px;
        font-weight: 500;
        color: #121525;
    }
    .sender_info{
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 40px;
        /* border-bottom: 1px solid #eaeaea; */
    }
    .sender_info img{
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }
    .sender_info .info{
        margin: 0;
    }
    .sender_info .info h5{
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }
    .sender_info .info h6{
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }
    .sender_info .info h6 span{
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }
    .msgFooter p{
        font-size: 13px;
        line-height: 21px;
    }
    .msgFooter p a{
        text-decoration: none;
    }
    .close_btn{
        background: #fff;
        width: 25px;
        height: 25px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        position: absolute;
        right: 30px;
        top: 20px;
        cursor: pointer;
    }

    :deep(.email-signature ul li) {
        list-style: none !important;
    }

    .email-logo {
        justify-content: center;
        margin-bottom: 10px;
        display: flex;
    }

    .email-logo img {
        height: 70px !important;
        width: auto;
        margin: auto;
    }

    .notif_preview_wpr{
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        padding: 15px 20px 0 20px;
    }
    body .notif_preview_wpr .action_wpr{
        margin: 0 -20px;
        padding: 20px;
    }
</style>
